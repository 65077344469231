import fetchNow from './_Fetch.jsx';

const ConfigurationAPI = {
    get: () => {
        return fetchNow('/ivosight', 'GET');
    },
    update: (body) => {
        return fetchNow('/ivosight', 'PUT', body);
    },
}

export default ConfigurationAPI;