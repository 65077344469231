import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { Copy } from 'react-feather';
import $ from 'jquery';
import Breadcrumbs from '../../components/Breadcrumb';
import Loading from '../../components/loading/Loading';
import { RootContext } from '../../Context';
import CopyToClipboard from 'react-copy-to-clipboard';
import ConfigurationAPI from '../../api/Configuration';
import { toast } from 'react-toastify';

class ApiKeyConfiguration extends Component {
    static contextType = RootContext;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            data: {
                "x_api_key": "",
                "webhook": ""
            },
            newApiKey: "",
        }

        this.modalEdit = this.modalEdit.bind(this);
        this.fetchNow = this.fetchNow.bind(this);
        this.update = this.update.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    modalEdit() {
        this.setState({
            newApiKey: this.state.data['x_api_key']
        }, () => { $('#edit-modal').modal('toggle') })
    }


    copied(target) {
        document.getElementById(target).classList.toggle('show');

        setTimeout(() => {
            document.getElementById(target).classList.toggle('show');
        }, 700);
    }

    fetchNow() {
        this.setState({
            isLoading: true
        }, () => {
            ConfigurationAPI.get().then((result) => {
                let data = [];

                if (result.status === 200) {
                    data = result.data;
                }
                this.setState({
                    isLoading: false,
                    data
                })
            })
        })
    }

    update() {
        this.setState({
            isLoading: true
        }, () => {
            let dataForm = new URLSearchParams({
                x_api_key: this.state.newApiKey
            })

            ConfigurationAPI.update(dataForm).then((result) => {
                if(result.status === 200) {
                    this.fetchNow();
                    $('#edit-modal').modal('toggle');
                }else{
                    this.setState({
                        isLoading: false
                    }, () => {
                        $('#edit-modal').modal('toggle');

                        toast.error(`Edit Api Key Failed (Status ${result.status})`, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    })
                }
            })
        })
    }

    handleChange(event) {
        this.setState({
            newApiKey: event.target.value
        })
    }

    componentDidMount() {
        this.fetchNow()
    }

    render() {
        return (
            <React.Fragment>
                <Loading show={this.state.isLoading} />
                <Helmet title={"API KEY Configuration" + process.env.REACT_APP_WEB_NAME} />
                <Breadcrumbs title="API KEY Configuration" />

                <Container fluid={false}>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="card">
                                <div className="card-header pt-4 pb-4">
                                    <div className="row">
                                        <div className="col-12 col-lg text-center text-lg-left mb-3 mb-lg-0">
                                            <h5 className="mt-2">API Key</h5>
                                        </div>
                                        <div className="col-12 col-lg-4 pl-lg-0">
                                            <button onClick={this.modalEdit} className="btn btn-success w-100 d-flex align-items-center justify-content-center mb-2 p-2">Edit Api Key</button>
                                        </div>
                                    </div>
                                </div>  
                                <div className="card-body">
                                    <CopyToClipboard text={this.state.data['x_api_key']} onCopy={this.copied.bind(this, 'text-url')}> 
                                        <div className="api-box mb-4">
                                            <div className="api-type">
                                                <div className="api-badge">
                                                    KEY
                                                </div>
                                            </div>
                                            <div className="api-url">
                                                <code className="text-dark" style={{fontSize: '14px'}}>{this.state.data['x_api_key']}</code>
                                                <Copy className="copy-icon ml-2" size={16}/>
                                            </div>
                                            <div id="text-url" className="badge badge-dark copy-status">Text Copied</div>
                                        </div>
                                    </CopyToClipboard>
                                    <span>Please input X-API-KEY from Ivosight</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="card">
                                <div className="card-header pt-4 pb-4">
                                    <div className="row">
                                        <div className="col-12 col-lg text-center text-lg-left mb-3 mb-lg-0">
                                            <h5 className="mt-2">Webhook URL</h5>
                                        </div>
                                    </div>
                                </div>  
                                <div className="card-body">
                                    <CopyToClipboard text={this.state.data['webhook']} onCopy={this.copied.bind(this, 'text-url')}> 
                                        <div className="api-box mb-4">
                                            <div className="api-type">
                                                <div className="api-badge">
                                                    URL
                                                </div>
                                            </div>
                                            <div className="api-url">
                                                <code className="text-dark" style={{fontSize: '14px'}}>{this.state.data['webhook']}</code>
                                                <Copy className="copy-icon ml-2" size={16}/>
                                            </div>
                                            <div id="text-url" className="badge badge-dark copy-status">URL Copied</div>
                                        </div>
                                    </CopyToClipboard>
                                    <span>Please add this url to Ivosight webhook</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
                <div className="modal fade" id="edit-modal" tabIndex="-1" aria-labelledby="contact modal" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit API KEY</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label style={{fontSize: '1rem', fontWeight: 500}}>API KEY</label>
                                    <input value={this.state.newApiKey} onChange={this.handleChange} type="text" name="x_api_key" placeholder="Enter API KEY" className="form-control" />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-primary" onClick={this.update}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ApiKeyConfiguration;