import React, { Component } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Loading from '../../../components/loading/Loading';
import EmptyData from '../../../assets/images/Empty-Data.svg';

/* No Data Available Alert */
const NoData = () =>
    <div className="status-info col-12">
        <img src={EmptyData} alt="Empty Data" />
        <p className="status-title">No Template Available</p>
    </div>

class Step1 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
        }
        this.fetchDetail = this.fetchDetail.bind(this);

    }

    fetchDetail(event) {
        let choice = event ? event.target.value : this.props.template.choice;
        this.props.template.setChoice(choice);
    }
    componentDidMount() {
        /* Scroll to Top */
        window.scrollTo(0, 0)
    }

    render() { 
        return (
            <React.Fragment>
                <Loading show={this.state.isLoading} />
                <div className="col-12">
                    <div className="card">
                        <div className="card-header py-4">
                            <div className="mx-0 d-flex align-items-center justify-content-between">
                                <h5 className="m-0 pt-1">Template Data</h5>
                                <select id="template-select" value={this.props.template.choice} onChange={this.fetchDetail} className="custom-select sync" style={{ width: 'auto' }}>
                                    <option value="">— Choose Template —</option>
                                    {
                                        Array.from(this.props.template.data.values()).map((value, index) => {
                                            return (
                                                <option value={value.id} key={index}>{value.label}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="card-body py-5">
                            { this.props.template.length === 0 ? NoData() : 
                                this.props.template.selected_template != null ?
                                    this.props.template.selected_template.components.map((value, index) => {
                                        return (
                                            <div key={index} className="form-group row">
                                                <label className="col-md-2 col-form-label f-w-500" >{value['type']}</label>
                                                <div className="col-md-6 d-flex">
                                                    <div className="summary-message-box">
                                                        {
                                                            value['text'] != null ?
                                                            value['text'].split("\n").map((item, index) => (
                                                                <React.Fragment key={index}>
                                                                    {item}
                                                                    <br />
                                                                </React.Fragment>
                                                            )) : "" 
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    value['type'] === 'BODY' ? 
                                                        <div className="col-md-4">
                                                            <div className="row">
                                                                <h6 className="f-w-500">PARAMETER</h6>
                                                                <span>Use @name, @phone to use name and phone number from contact</span>
                                                            </div>
                                                            {
                                                                value['parameters'].length >  0 ? value['parameters'].map((values, index2) => (
                                                                    <div className="row" key={index2}>
                                                                        <div className="input-group">                             
                                                                            <div className="input-group-prepend">                                 
                                                                                <span className="input-group-text">
                                                                                    <small>{values['label']}</small>
                                                                                </span>                             
                                                                            </div>                             
                                                                            <input type="text" value={values['text']} onChange={(e) => this.props.template.onParamChange(e, index, index2)} className="form-control input-parameter" />
                                                                        </div>
                                                                    </div>
                                                                )): ""
                                                            }
                                                        </div>: ""
                                                }
                                            </div>
                                        )
                                    }) : ""
                            }
                        </div>
                    </div>
                </div>

                <div className="col-12 d-flex justify-content-end mb-4">
                    <button onClick={this.props.iterateStep} data-direction="next" className="btn btn-green">Next</button>
                </div>
            </React.Fragment>
        );
    }
}
 
export default Step1;